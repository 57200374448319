import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarThree from '../components/Index-3/NavbarThree'
import Banner from '../components/Index-3/Banner'
import AboutThree from '../components/Index-3/AboutThree'
import ServicesThree from '../components/Index-3/ServicesThree'
import ProjectTwo from '../components/Index-3/ProjectTwo'
import WhyChooseThree from '../components/Index-3/WhyChooseThree'
import Achievements from '../components/Common/Achievements'
import Team from '../components/Common/Team'
import WhyOnlyThree from '../components/Common/WhyOnlyThree'
import TestimonialThree from '../components/Index-3/TestimonialThree'
import BlogPost from "../components/Common/BlogPost"
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const IndexPageThree = () => {
    return (
        <Layout>
            <Seo title="Home Three" />

            <NavbarThree />

            <Banner />

            <AboutThree />

            <ServicesThree />

            <ProjectTwo />

            <WhyChooseThree />

            <div className="fun-facts fun-facts-3">
                <Achievements />
            </div>

            <Team />

            <div className="bg-f8f8f8">
                <WhyOnlyThree />
            </div>

            <TestimonialThree />

            <div className="bg-f8f8f8">
                <BlogPost />
            </div>

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default IndexPageThree
