import React from 'react'

// Images 
import testimonialImg from '../../assets/images/testimonial/tc2.jpg'
import testimonialImg2 from '../../assets/images/testimonial/tc3.jpg'
import testimonialImg3 from '../../assets/images/testimonial/tc4.jpg'
import testimonialImg4 from '../../assets/images/testimonial/tc5.jpg'
import testimonialImg5 from '../../assets/images/testimonial/tc6.jpg'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    nav: false,
    responsiveClass: true,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        992: {
            items: 2,
        },
        1200: {
            items: 1.5,
        },
        1400: {
            items: 2,
        },
        1550: {
            items: 2.4,
        },
    }
};

const TestimonialThree = () => {
    return (
        <section className="testimonial testimonial-3 ptb-70"> 
            <div className="row align-items-center">
                <div className="col-xl-4">
                    <div className="testimonial-text-area">
                        <div className="default-section-title">
                            <span>Testimonials</span>   
                            <h3>What Our Clients Say About Us</h3>
                        </div>
                        <p>Lorem ipsum dolor sit co adipiscing elit, sed eiusmo accums facilisis.</p>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="testimonial-card-area-3">
                        <OwlCarousel 
                            className="testimonial-slider-area-3 owl-carousel"
                            {...options}
                            >
                            <div className="testimonial-card">
                                <img 
                                    src={testimonialImg} 
                                    alt="testimonial"
                                />
                                <div className="testimonial-card-text">
                                    <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                    <div className="testimonial-intro-area">
                                        <div className="testimonial-card-intro">
                                                <h4>Robert Cook</h4>
                                                <span>Front End Developer</span>
                                        </div>
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial-card">
                                <img 
                                    src={testimonialImg2} 
                                    alt="testimonial"
                                />
                                <div className="testimonial-card-text">
                                    <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                    <div className="testimonial-intro-area">
                                        <div className="testimonial-card-intro">
                                                <h4>Danial Cook</h4>
                                                <span>Back End Developer</span>
                                        </div>
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial-card">
                                <img 
                                    src={testimonialImg3} 
                                    alt="testimonial"
                                />
                                <div className="testimonial-card-text">
                                    <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                    <div className="testimonial-intro-area">
                                        <div className="testimonial-card-intro">
                                                <h4>Peter Smith</h4>
                                                <span>Front End Developer</span>
                                        </div>
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial-card">
                                <img 
                                    src={testimonialImg4} 
                                    alt="testimonial"
                                />
                                <div className="testimonial-card-text">
                                    <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                    <div className="testimonial-intro-area">
                                        <div className="testimonial-card-intro">
                                            <h4>Robert Brown</h4>
                                            <span>Manager</span>
                                        </div>
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial-card">
                                <img 
                                    src={testimonialImg5} 
                                    alt="testimonial"
                                />
                                <div className="testimonial-card-text">
                                    <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                    <div className="testimonial-intro-area">
                                        <div className="testimonial-card-intro">
                                                <h4>Stive Smith</h4>
                                                <span>Front End Developer</span>
                                        </div>
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialThree
