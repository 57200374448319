import React from 'react'
import Navbar from '../_App/Navbar'

const NavbarThree = () => {
    return (
        <div className="header-area header-1 header-3 bg-071327">
            <Navbar />
        </div>
    )
}

export default NavbarThree
