import { Link } from 'gatsby'
import React from 'react'

// Images 
import serviceShape from '../../assets/images/shape/shape4.png'
import serviceShape2 from '../../assets/images/shape/shape5.png'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    items: 1,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }
};

const ServicesThree = () => {
    return (
        <section className="services service-3  ptb-100 bg-f8f8f8">
            <div className="shape" >
                <div className="shape1" data-speed="0.2" data-revert="true">
                    <img 
                        src={serviceShape} 
                        alt="service shape" 
                    />
                </div>
                <div className="shape2" data-speed="0.1" data-revert="true">
                    <img 
                        src={serviceShape2} 
                        alt="service shape" 
                    />
                </div>
            </div>

            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Services</span>   
                    <h3>Services For Business</h3>
                </div>
                <div className="section-content">
                    <OwlCarousel 
                        className="service-slider-area-3 owl-carousel owl-theme"
                        {...options}
                        >
                        <div className="service-card-3">
                            <i className="flaticon-web-design"></i>
                            <h4><Link to="/service-details">Modern Website Design</Link></h4>
                            <p>Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.</p>
                            <Link className="read-more-btn" to="/service-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>

                        <div className="service-card-3">
                            <i className="flaticon-content-writing"></i>
                            <h4><Link to="/service-details">SEO And Content Writing</Link></h4>
                            <p>Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.</p>
                            <Link className="read-more-btn" to="/service-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>

                        <div className="service-card-3">
                            <i className="flaticon-application"></i>
                            <h4><Link to="/service-details">Web Development</Link></h4>
                            <p>Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.</p>
                            <Link className="read-more-btn" to="/service-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>

                        <div className="service-card-3">
                            <i className="flaticon-idea-1"></i>
                            <h4><Link to="/service-details">App Development</Link></h4>
                            <p>Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.</p>
                            <Link className="read-more-btn" to="/service-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>

                        <div className="service-card-3">
                            <i className="flaticon-design-process"></i>
                            <h4><Link to="/service-details">Innovative Idea</Link></h4>
                            <p>Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.</p>
                            <Link className="read-more-btn" to="/service-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    )
}

export default ServicesThree
