import React from 'react'
import { Link } from 'gatsby'

// Images 
import bannerShape from '../../assets/images/shape/shape1.png'
import bannerImg from '../../assets/images/banner/banner-3-img.jpg'


const Banner = () => {
    return (
        <div className="banner">
            <div className="shape">
                <img 
                    className="shape1" 
                    src={bannerShape} 
                    alt="Shape"
                />
            </div>

            <div className="banner-content-3">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="banner-img-3">
                            <img 
                                src={bannerImg} 
                                alt="Banner"
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="banner-text-area banner-text-area-3 pl-20">
                            <h6>We Are Digital Marketing Expert</h6>
                            <h1>We're Anxiety Perfect Solution For Company</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Risus commodo viverra, sed gravida. Risus commodo viverra maecen facilisis. </p>
                            <div className="banner-buttons">
                                <ul>
                                    <li><Link className="default-button" to="/contact">Contact Now <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link className="default-button banner-button" to="/services">Explore More <i className="fas fa-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
