import React from 'react'

// Images 
import aboutImg from '../../assets/images/testimonial/tc1.jpg'
import aboutImg2 from '../../assets/images/about/a3.jpg'
import aboutImg3 from '../../assets/images/about/a3-2.jpg'
import aboutShape from '../../assets/images/shape/shape4.png'
import aboutShape2 from '../../assets/images/shape/shape5.png'

const AboutThree = () => {
    return (
        <section className="about ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-text-area-2 pr-20">
                            <div className="default-section-title">
                                <span>About Our Company</span>   
                                <h3>Get To Audience With Targeted Ads On Associative Media</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice.Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <div className="about-list">
                                <ul>
                                    <li><i className="fas fa-check"></i> We do Give Our Best</li>
                                    <li><i className="fas fa-check"></i> Traffic Analysis</li>
                                    <li><i className="fas fa-check"></i> Highly Professionalism</li>
                                    <li><i className="fas fa-check"></i> Robust Web Plan</li>
                                    <li><i className="fas fa-check"></i> Cross-border Operability</li>
                                    <li><i className="fas fa-check"></i> Dedicated Team</li>
                                </ul>
                            </div>
                            <div className="about-intro">
                                <img 
                                    src={aboutImg} 
                                    alt="about"
                                />
                                <div className="at-text">
                                    <h4>Adam Rueter</h4>
                                    <p>Founder Manager</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-img-area about-img-area-3">
                            <img 
                                className="main-img" 
                                src={aboutImg2} 
                                alt="about"
                            />
                            <img 
                                className="small-img" 
                                src={aboutImg3} 
                                alt="about"
                            />
                            <div className="shape">
                                <img 
                                    className="shape1" 
                                    src={aboutShape} 
                                    alt="about shape"
                                />
                                <img 
                                    className="shape2" 
                                    src={aboutShape2} 
                                    alt="about shape"
                                />
                            </div>
                            <div className="about-exp">
                                <h4>45+</h4>
                                <p>Years Of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutThree
