import React from 'react'
import { Link } from 'gatsby'

// Images 
import portfolioShape from '../../assets/images/shape/shape2.png'
import portfolioImg from '../../assets/images/portfolio/p7.jpg'
import portfolioImg2 from '../../assets/images/portfolio/p8.jpg'
import portfolioImg3 from '../../assets/images/portfolio/p9.jpg'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    margin: 10,
    nav: true,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsiveClass: true,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
    }
};

const ProjectTwo = () => {
    return (
        <section className="portfolio ptb-100"> 
            <div className="shape">
                <img 
                    src={portfolioShape} 
                    alt="shape"
                />
            </div>
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Project</span>   
                    <h3>We Have Done Of Our Awesome Project</h3>
                </div>

                <div className="section-content">
                    <OwlCarousel 
                        className="portfolio-slider-area-3 owl-carousel slider-area-nav"
                        {...options}
                        >
                        <div className="portfolio-card-3">
                            <img 
                                src={portfolioImg} 
                                alt="portfolio"
                            />
                            <div className="portfolio-card-text-3">
                                <h4><Link to="/portfolio-deatils">Design & Market Research</Link></h4>
                                <p>Lorem ipsum dolor sit amet, elit, magna Quis ipsum suspendisse facilisis. </p>
                            </div>
                        </div>

                        <div className="portfolio-card-3">
                            <img 
                                src={portfolioImg2} 
                                alt="portfolio"
                            />
                            <div className="portfolio-card-text-3">
                                <h4><Link to="/portfolio-deatils">Project Strategy</Link></h4>
                                <p>Lorem ipsum dolor sit amet, elit, magna Quis ipsum suspendisse facilisis. </p>
                            </div>
                        </div>

                        <div className="portfolio-card-3">
                            <img 
                                src={portfolioImg3} 
                                alt="portfolio"
                            />
                            <div className="portfolio-card-text-3">
                                <h4><Link to="/portfolio-deatils">SEO & Marketing</Link></h4>
                                <p>Lorem ipsum dolor sit amet, elit, magna Quis ipsum suspendisse facilisis. </p>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    )
}

export default ProjectTwo
