import { Link } from 'gatsby'
import React from 'react'

// Images 
import chooseImg from '../../assets/images/why-we/ww5.jpg'


const WhyChooseThree = () => {
    return (
        <div>
            <section className="why-we bg-f8f8f8 why-we-3">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-we-img-3">
                            <img 
                                src={chooseImg} 
                                alt="choose"
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="why-we-text-3 pl-20">
                            <div className="default-section-title">
                                <span>Why Choose US</span>   
                                <h3>Poly Reasons Why You Should Nominate Us</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <div className="why-we-3-card-area">
                                <div className="why-we-card-3">
                                    <i className="flaticon-idea-1"></i>
                                    <h4><Link to="/services">Your Business From Creative Idea</Link></h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ipsum suspendisse  maecenas accumsan lacus facilisis. </p>
                                </div>

                                <div className="why-we-card-3">
                                    <i className="flaticon-design-process"></i>
                                    <h4><Link to="/services">Our Creative Financial Design</Link></h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ipsum suspendisse  maecenas accumsan lacus facilisis. </p>
                                </div>

                                <div className="why-we-card-3">
                                    <i className="flaticon-experience"></i>
                                    <h4><Link to="/services">New Digital Experiences</Link></h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ipsum suspendisse  maecenas accumsan lacus facilisis. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WhyChooseThree
